import "./Loader.scss"

export function Loader() {
    return <svg className='sl-loader' width="161" height="161" viewBox="0 0 161 161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <rect x="33" y="73" width="95" height="14" fill="#00C1F3" rx="4" ry="4" id="b1" className="b"/>
            <rect x="33" y="73" width="95" height="14" fill="#FFD300" rx="4" ry="4" id="b2" className="b"/>
            <rect x="33" y="73" width="95" height="14" fill="#FF00AA" rx="4" ry="4" id="b3" className="b"/>
            <rect x="33" y="73" width="95" height="15" fill="#000000" rx="4" ry="4" id="b4" className="b"/>
        </g>
    </svg>
}