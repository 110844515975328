import React, {RefObject, useState} from "react"
import "./ViewProject.scss"
import "./Dialog.scss"
import {Project, State} from "../util/Workflow"
import {EnumBubble} from "../components/EnumBubble"
import TimeAgo from 'react-timeago'
import {UserBubble} from "../components/UserBubble"
import {ReactComponent as Close} from "../assets/cancel_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {FileIcon} from "../components/FileIcon";

interface Props {
    project?: Project
    externalRef: RefObject<HTMLDialogElement>
}

export function ViewProject({project, externalRef}: Props) {
    const [showAll, setShowAll] = useState("")

    function close() {
        setShowAll("")
        externalRef.current?.close()
    }

    return <dialog className='view-project dialog' ref={externalRef}>
        <div className='close' onClick={close}><Close/></div>
        <div className='content'>
            <div className='prompt'>{project?.title}</div>
            <div className='details'>
                <EnumBubble value={project?.state} enum={State}/>
                <span>Created: {project?.created && <TimeAgo date={project.created}/>}</span>
                <span>Updated: {project?.updated && <TimeAgo date={project.updated}/>}</span>
                <span>Deadline: {project?.deadline && <TimeAgo date={project?.deadline}/>}</span>
                <span>Delivery: {project?.delivery && <TimeAgo date={project?.delivery}/>}</span>
            </div>
            <div className='assignees'>
                <div>Producer:</div>
                <UserBubble user={project?.producer}/>
                <div>Voice:</div>
                <UserBubble user={project?.voice}/>
                <div>Writer:</div>
                <UserBubble user={project?.writer}/>
            </div>
            <div className='editor'>
                Brief: {project?.briefs && (project.briefs.length ?? 0) > 1 &&
                <span>{project.briefs.length - 1} previous versions</span>}
                <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.briefs && (project.briefs.length > 0)) ? project.briefs[project.briefs.length - 1].text : ''}}/>
            </div>
            {project?.scripts && <div className='editor'>
                Script: {(project.scripts.length ?? 0) > 1 &&
                <span>{project.scripts.length - 1} previous versions</span>}
                <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.scripts && (project.scripts.length > 0)) ? project.scripts[project.scripts.length - 1].text : ''}}/>
            </div>}
            {project?.notes && <div className='editor'>
                Script: {(project.notes.length ?? 0) > 1 &&
                <span>{project.notes.length - 1} previous versions</span>}
                <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.notes && (project.notes.length > 0)) ? project.notes[project.notes.length - 1].text : ''}}/>
            </div>}
            <div className='recording'>
                <div className='title'>Recording: {project?.recordings && project.recordings.length > 1 &&
                    <span className='show-all'
                          onClick={() => setShowAll('recordings')}>{project.recordings.length - 1} previous recordings</span>}</div>
                {project?.recordings && project.recordings.length > 0 ? <div className='recordings'>
                    {showAll === 'recordings' ? <>
                        {project.recordings.reverse().map(r => <div className='revision'>
                            <audio controls>
                                <source src={r.text}/>
                            </audio>
                            Recorded by <UserBubble user={r.user}/><TimeAgo date={r.timestamp}/>
                        </div>)}</> : <div className='revision'>
                        <audio controls>
                            <source src={project.recordings[project.recordings.length - 1].text}/>
                        </audio>
                        Recorded by <UserBubble user={project.recordings[project.recordings.length - 1].user}/><TimeAgo
                        date={project.recordings[project.recordings.length - 1].timestamp}/>
                    </div>
                    }
                </div> : <div>
                    No recording yet
                </div>}
            </div>
            {project?.feedbacks && <div className='editor'>
                Feedback: {(project.feedbacks.length ?? 0) > 1 &&
                <span>{project.feedbacks.length - 1} previous versions</span>}
                <div className='text-box ql-editor ql-snow' dangerouslySetInnerHTML={{__html: (project?.feedbacks && (project.feedbacks.length > 0)) ? project.feedbacks[project.feedbacks.length - 1].text : ''}}/>
            </div>}

            <div className='attachments'>
                <div className='title'>Attachments:</div>
                <div className='files'>
                    {project?.attachments?.map(a => <FileIcon attachment={a}/>)}
                </div>
                {!project?.attachments && <div>No attachments yet</div>}
            </div>
            <div className='buttons'>
                <div className='cancel button' onClick={close}>Close</div>
            </div>
        </div>
    </dialog>
}