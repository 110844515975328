import React from "react"
import "./FileIcon.scss"
import {ReactComponent as AudioFile} from "../assets/audio_file_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as VideoFile} from "../assets/video_file_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {ReactComponent as File} from "../assets/description_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"

interface Props {
    attachment: {text: string, filename: string, contentType: string}
}

export function FileIcon({attachment}: Props) {

    return <div className='file-icon'>
        <a href={attachment.text} target='_blank' rel='noreferrer'>
            {attachment.contentType.startsWith('audio/') ? <AudioFile/> : attachment.contentType.startsWith('video/') ?
                <VideoFile/> : <File/>}
        </a>
        <div className='name'>{attachment.filename}</div>
    </div>
}