import "./Attachments.scss"
import "./Dialog.scss"
import {Project} from "../util/Workflow";
import React, {RefObject, useState} from "react";
import {ReactComponent as Close} from "../assets/cancel_24dp_UNDEFINED_FILL0_wght400_GRAD0_opsz24.svg"
import {Loader} from "../components/Loader";
import {Drop} from "../components/Drop";
import {FileIcon} from "../components/FileIcon";
import Network from "../util/Network";

interface Props {
    project: Project
    externalRef: RefObject<HTMLDialogElement>
    updateProject: (p:Project) => void
}

export function Attachments(props: Props) {
    const [newAttachments, setNewAttachments] = useState<Array<File>>([])
    const [loading, setLoading] = useState(false)

    function close() {
        setNewAttachments([])
        props.externalRef.current?.close()
    }

    async function attach() {
        setLoading(true)
        let newProj = props.project
        for (let file of newAttachments) {
            const url = await Network.uploadFile(props.project._id, file.name, file.type, await file.arrayBuffer())
            if (url) {
                const proj = await Network.addAttachment(props.project._id, file.name, url, file.type)
                if (proj)
                    newProj = proj
            }
        }
        props.updateProject(newProj)
        setLoading(false)
        close()
    }

    function addFile(file: File) {
        setNewAttachments([...newAttachments, file])
    }

    return <dialog className='dialog attachments' ref={props.externalRef}>
        <div className='close' onClick={close}><Close/></div>
        <div className='content'>
            <div className='project-title'>Project: {props.project?.title}</div>
            <div>
                <div className='title'>Attachments:</div>
                <div className='files'>
                    {props.project?.attachments?.map(a => <FileIcon attachment={a}/>)}
                    {newAttachments.map(a => <FileIcon attachment={{text: URL.createObjectURL(a), filename: a.name, contentType: a.type}}/>)}
                </div>
                {!props.project?.attachments && (newAttachments.length === 0) && <div>No attachments yet</div>}
            </div>
            <Drop drop={addFile}>Click to upload a file, or drag and drop here</Drop>
            <div className='buttons'>
                <div className='cancel button' onClick={close}>Cancel</div>
                <div className={'create button' + ((newAttachments.length > 0) ? "" : " disabled")}
                     onClick={attach}>Attach
                </div>
            </div>
        </div>
        <div className={'loader' + (loading ? " loading" : "")}><Loader/></div>
    </dialog>
}