import "./Drop.scss"
import React, {ReactNode, useState, DragEvent, useRef} from "react"

interface Props {
    children?: ReactNode
    allowed?: string
    multiple?: boolean
    drop: (f: File) => void
}

export function Drop(props: Props) {
    const [state, setState] = useState<""|"red"|"green">("")
    const [error, setError] = useState("")
    const fileRef = useRef<HTMLInputElement>(null)

    function enter(e:DragEvent) {
        const items = e.dataTransfer.items
        if (items.length !== 1) {
            setError("One file at a time")
            setState("red")
        } else {
            const item = items[0]
            if (!props.allowed || item.type.startsWith(props.allowed))
                setState("green")
            else {
                setError("Wrong file type")
                setState("red")
            }
        }
    }

    function leave() {
        setState("")
    }

    function drop(e:DragEvent) {
        e.preventDefault()
        const items = e.dataTransfer.items
        if (items.length === 1 && (!props.allowed || items[0].type.startsWith(props.allowed))) {
            const f = items[0].getAsFile()
            if (f)
                props.drop(f)
        }
        setState("")
    }

    function over(e:DragEvent) {
        e.preventDefault()
    }

    async function uploadFile() {
        const files = fileRef?.current?.files
        if (files) {
            for (let i = 0; i < files.length; i++) {
                props.drop(files[i])
            }
            fileRef.current.value = ""
        }
    }

    return <div className={`drop ${state}`} onDragEnter={enter} onDragLeave={leave} onDrop={drop} onDragOver={over} onClick={() => fileRef.current?.click()}>
        {(state === "red" && error) ? <div className='error'>{error}</div> : props.children }
        <input type='file' ref={fileRef} style={{display: 'none'}} onChange={uploadFile} multiple={props.multiple}/>
    </div>
}