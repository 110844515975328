import "./UsersDropdown.scss"
import {useContext, useState} from "react"
import {UserBubble} from "./UserBubble"
import {AppContext} from "../App";

interface Props {
    user:string
    setUser:(user:string) => void
}

export function UsersDropdown({user, setUser}:Props) {
    const [show, setShow] = useState(false)
    const context = useContext(AppContext)

    return <div className='users-dropdown'>
        <UserBubble user={user} onClick={()=>setShow(!show)}/>
        <div className={'list'+(show?" show":"")}>
            {context?.users.map(u => <UserBubble user={u} onClick={() => {
                setShow(false)
                setUser(u._id)
            }}/>)}
        </div>
    </div>

}