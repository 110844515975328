import React, {createContext, useEffect, useRef, useState} from 'react'
import './App.css'
import Header from "./components/Header"
import {usePageVisibility} from 'react-page-visibility'
import Network, {User} from "./util/Network"
import {Projects} from "./components/Projects"
import {NewProject} from "./dialogs/NewProject"
import {Project, Workflow} from "./util/Workflow"
import {Loader} from "./components/Loader";

interface AppContextType {
    currentUser: User
    users: User[]
    workflow: Workflow
    refresh: () => Promise<void>
}
export const AppContext = createContext<AppContextType|undefined>(undefined)

function App() {
    const pageVisibility = usePageVisibility()
    const [search, setSearch] = useState('')
    const [projects, setProjects] = useState<Array<Project> | undefined>()
    const [context, setContext] = useState<AppContextType>()
    const [error, setError] = useState<string>()
    const newRef = useRef<HTMLDialogElement>(null)

    async function loadProjects() {
        return Network.getProjects(search).then(setProjects)
    }

    function updateProject(proj: Project) {
        if (!projects) {
            setProjects([proj])
        } else {
            const newProjects: Array<Project> = []
            let found = false
            for (let i = 0; i < projects.length; i++) {
                if (projects[i]._id === proj._id) {
                    newProjects.push(proj)
                    found = true
                } else {
                    newProjects.push(projects[i])
                }
            }
            if (!found)
                newProjects.push(proj)
            setProjects(newProjects)
        }
    }

    function updateSearch(search: string) {
        setSearch(search)
    }

    async function refreshContext() {
        await Promise.all([Network.getUsers(), Network.getProjects(), Network.getCurrentUser(), Network.getWorkflow()]).then(ps => {
            if (ps[0] && ps[1] && ps[2] && ps[3]) {
                setContext({
                    users: ps[0].sort((a,b) => a.name.localeCompare(b.name)),
                    currentUser: ps[2],
                    workflow: ps[3],
                    refresh: refreshContext
                })
                setProjects(ps[1])
            } else
                setError("Failed to load users from server")
        })
    }

    useEffect(() => {
        if (pageVisibility && context) {
            loadProjects()
            const id = setInterval(loadProjects, 30000)
            return () => clearInterval(id)
        }
    }, [pageVisibility, search, context])

    useEffect(() => {
        if (!context) {
            refreshContext().catch(setError)
        }
    }, [context]);

    if (error || !context)
        return <div className='loading'>
            <Loader/>
            <div className='error'>{error ?? "Loading..."}</div>
        </div>
    else
        return <div className="App">
        <AppContext.Provider value={context}>
            <Header startProject={() => newRef.current?.showModal()} updateSearch={updateSearch}/>
            <Projects projects={projects ?? []} updateProject={updateProject} refresh={loadProjects}
                      startProject={() => newRef.current?.showModal()}/>
            <NewProject updateProject={updateProject} externalRef={newRef}/>
        </AppContext.Provider>
    </div>
}

export default App;
