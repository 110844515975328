import {CSSProperties} from "react"

// const colors = [
//     // "#6935EC", "#00ADA3", "#EC795C", "#348EE1"
//     "hsl(0, 80%, 30%)", "hsl(36, 80%, 30%)", "hsl(72, 80%, 30%)", "hsl(108, 80%, 30%)", "hsl(144, 80%, 30%)",
//     "hsl(180, 80%, 30%)", "hsl(216, 80%, 30%)", "hsl(252, 80%, 30%)", "hsl(288, 80%, 30%)", "hsl(324, 80%, 30%)",
// ]
// const dark = [
//     // "#4926A3", "#238F89", "#D05A3D", "#186BCC"
//     "hsl(0, 80%, 25%)", "hsl(36, 80%, 25%)", "hsl(72, 80%, 25%)", "hsl(108, 80%, 25%)", "hsl(144, 80%, 25%)",
//     "hsl(180, 80%, 25%)", "hsl(216, 80%, 25%)", "hsl(252, 80%, 25%)", "hsl(288, 80%, 25%)", "hsl(324, 80%, 25%)",
// ]

const numColors = 8
const sat = 80
const lumLight = 30
const lumDark = 25

const light = (i: number) => `hsl(${360*i/numColors}, ${sat}%, ${lumLight}%)`
const dark = (i: number) => `hsl(${360*i/numColors}, ${sat}%, ${lumDark}%)`

export function hash(s: string) {
    let d = 0
    for (let i = 0; i < s.length; i++) {
        d = (d * 11 + s.charCodeAt(i))%256
    }
    return d
}

export function hashColors(s: string | number) : CSSProperties {
    const d = (typeof s === 'string' ? hash(s) : s) % numColors
    return {"--bg": light(d), "--dark":dark(d)} as CSSProperties
}

export const defaultColors = {"--bg": "hsl(0, 0%, 50%)", "--dark":"hsl(0, 0%, 30%)"} as CSSProperties